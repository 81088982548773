import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';

import Breadcrumbs from '../components/Breadcrumbs';
import CustomMDXProvider from '../components/CustomMDXProvider';
import NOPSidebarBanner from '../components/NOPSidebarBanner';
import Seo from '../components/Seo';
import ShareButtons from '../components/ShareButtons';

import escapeForHTML from '../utils/escapeForHTML';

const blogPage = ({ data, location, pageContext }) => {
  const { blog, site } = data;

  return (
    <>
      <Seo
        title={escapeForHTML(blog.meta.title)}
        description={escapeForHTML(blog.meta.description)}
      >
        <script type='application/ld+json'>
          {`{
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "name": "${escapeForHTML(blog.meta.title)}",
              "description": "${escapeForHTML(blog.meta.description)}",
              "author": "${blog.author}",
              "datePublished": "${blog.date}",
              "url": "${site.siteMetadata.siteUrl}${location.pathname}"
          }`}
        </script>
      </Seo>
      <div className="container-md py-10 md:py-20">
        <Breadcrumbs
          crumbs={pageContext.breadcrumb.crumbs}
          crumbLabel={blog.title}
        />
        <h1 className="typo-h1 my-4">
          {blog.title}
        </h1>
        <div className="row mt-4 md:mt-10">
          <div className="col-full md:col-8">
            <CustomMDXProvider>
              <MDXRenderer>
                {blog.content.body}
              </MDXRenderer>
            </CustomMDXProvider>
          </div>
          <div className="col-full md:col-4 mt-8 md:mt-0">
            <NOPSidebarBanner medium="blog-sidebar" />
          </div>
        </div>
        <p className="typo-small text-gray-700 mt-5 md:mt-10">
          теги: {blog.tags.join(' • ')}
        </p>
        <p className="typo-small text-gray-700 mt-5">
          автор: {blog.author}
        </p>
        <div className="mt-5 md:mt-14">
          <h3 className="typo-h2">Поділитися</h3>
          <ShareButtons
            className="mt-4"
            title={blog.title}
            url={`${site.siteMetadata.siteUrl}${location.pathname}`}
          />
        </div>
      </div>
    </>
  )
};

export default blogPage;

export const pageQuery = graphql`
  query($slug: String!) {
    blog: blog(slug: { eq: $slug }) {
      title
      date(formatString: "DD.MM.YYYY")
      author
      tags
      content {
        body
      }
      meta {
        title
        description
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
